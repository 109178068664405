import { Link, LinkProps } from "@ui/components/Link/Link";
import { FC, PropsWithChildren } from "react";

interface Props extends LinkProps {
    prefix?: string;
}

export const FooterMenuItem: FC<PropsWithChildren<Props>> = (props) => {
    const { children, prefix, route, ...restProps } = props;

    return (
        <p className="ui-typo-body2 mt-3.5 font-medium" role="listitem">
            {prefix && `${prefix}\xa0`}
            {route ? (
                <Link route={route} {...restProps}>
                    {children}
                </Link>
            ) : (
                children
            )}
        </p>
    );
};
