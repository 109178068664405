import { HTMLAttributes } from "react";
import styled from "styled-components";

type ContentContainerProps = HTMLAttributes<HTMLSpanElement>;

export const ContentContainer = styled("span")<ContentContainerProps>`
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;
`;
