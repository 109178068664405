const callbackPhoneNumberRaw = "253253100";
const callbackPhoneNumber = "253\xa0253\xa0100";
const energoCalculatorResultHeader = "energo-calculation-header";
const email = "info@kalkulator.cz";
const emailInsurance = "pojisteni@kalkulator.cz";

export const CommonConstants = {
    callbackPhoneNumberRaw,
    callbackPhoneNumber,
    energoCalculatorResultHeader,
    email,
    emailInsurance,
};
