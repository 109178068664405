export const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
};

export type Breakpoints = typeof BREAKPOINTS;
export type BreakpointsSet = keyof typeof BREAKPOINTS;
