import type { KalkulatorCookieConsent } from "@shared/utils/cookieConsent/cookie-consent.types";

export function createCookieConsentData(granted: boolean): KalkulatorCookieConsent {
    return {
        ad_personalization: granted,
        ad_storage: granted,
        ad_user_data: granted,
        analytics_storage: granted,
        functional: granted,
    };
}
