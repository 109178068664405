import { COLORS } from "@ui/theme/colors";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Button, ButtonProps } from "./Button";

type ButtonGreenPrimaryProps = Omit<
    ButtonProps,
    "$outline" | "$outlineBackgroundColor" | "$outlineBackgroundHoverColor"
>;

const ButtonGreenPrimaryComponent: ForwardRefRenderFunction<HTMLAnchorElement, ButtonGreenPrimaryProps> = (
    props,
    ref,
) => (
    <Button
        $color={COLORS.green.default}
        $textColor={COLORS.yellow.default}
        $textTransform="uppercase"
        ref={ref}
        {...props}
    />
);

export const ButtonGreenPrimary = forwardRef(ButtonGreenPrimaryComponent);

ButtonGreenPrimary.displayName = "ButtonGreenPrimary";
