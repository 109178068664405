import { COLORS } from "@ui/theme/colors";
import { FC, PropsWithChildren, memo } from "react";
import { LoaderDot } from "./styles/LoaderDot";
import { LoaderRoot, LoaderRootProps } from "./styles/LoaderRoot";

export type LoaderProps = LoaderRootProps;

const LoaderComponent: FC<PropsWithChildren<LoaderProps>> = (props) => {
    const { $color = COLORS.green.default, ...restProps } = props;

    return (
        <LoaderRoot aria-busy="true" role="alert" $color={$color} {...restProps}>
            <svg fill="currentColor" height={18} role="img" width={30} viewBox="0 0 30 18">
                <title>Načítám</title>
                <g>
                    <LoaderDot $height={18} cx="3" cy="9" r="3" />
                    <LoaderDot $height={18} cx="15" cy="9" r="3" />
                    <LoaderDot $height={18} cx="27" cy="9" r="3" />
                </g>
            </svg>
        </LoaderRoot>
    );
};

export const Loader = memo(LoaderComponent);

Loader.displayName = "Loader";
