import { COLORS } from "@ui/theme/colors";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Button, ButtonProps } from "./Button";

type ButtonGreenProps = Omit<ButtonProps, "$outline" | "$outlineBackgroundColor" | "$outlineBackgroundHoverColor">;

const ButtonGreenComponent: ForwardRefRenderFunction<HTMLAnchorElement, ButtonGreenProps> = (props, ref) => (
    <Button $color={COLORS.green.default} $loaderColor={COLORS.white} $textTransform="uppercase" ref={ref} {...props} />
);

export const ButtonGreen = forwardRef(ButtonGreenComponent);

ButtonGreen.displayName = "ButtonGreen";
