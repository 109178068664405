import { LinkProps, RouteList } from "@app-routes";
import { UseAnchorProps, useAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import Link from "next/link";
import { AnchorHTMLAttributes, forwardRef } from "react";
import { routes } from "../../../routes/routes";

export interface ClickableProps<T = RouteList, R extends keyof T = keyof T>
    extends Omit<LinkProps, "passHref" | "route" | "onTouchStart">,
        UseAnchorProps,
        Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> {
    route?: R;
}

export const Clickable = forwardRef<HTMLAnchorElement, ClickableProps>((props, ref) => {
    const { children, href, locale, params, replace, route, scroll, shallow, ...restProps } = props;

    const clickableProps = useAnchorProps<AnchorHTMLAttributes<HTMLAnchorElement>>({
        ...restProps,
        href: route ? " " : href,
    });

    if (route) {
        return (
            <Link
                href={{
                    pathname: routes[route],
                    query: params,
                }}
                locale={locale}
                passHref
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                prefetch={false}
                legacyBehavior
            >
                <a ref={ref} {...clickableProps}>
                    {children}
                </a>
            </Link>
        );
    }

    return (
        <a ref={ref} {...clickableProps}>
            {children}
        </a>
    );
});

Clickable.displayName = "Clickable";
