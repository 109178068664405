import { SVGAttributes } from "react";
import styled, { css, keyframes } from "styled-components";

const dotTyping = (height: number) => keyframes`
    0% {
        transform: translateY(0);
}
    10% {
        transform: translateY(-${(6 / height) * 100}%);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
    
`;

export interface LoaderDotProps extends SVGAttributes<SVGCircleElement> {
    $height: number;
}

export const LoaderDot = styled("circle")<LoaderDotProps>(
    ({ $height }) => css`
        animation: ${dotTyping($height)} 1s ease-in-out infinite;

        &:first-child {
            animation-delay: -0.2s;
        }

        &:last-child {
            animation-delay: 0.2s;
        }
    `,
);
