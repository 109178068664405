import { ButtonText, ButtonTextProps } from "@ui/components/Typography/ButtonText";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { Paddings } from "@uxf/styles/properties/paddings";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import styled, { css } from "styled-components";

export interface TextContainerProps extends ButtonTextProps, Pick<Paddings, "$px" | "$pl" | "$pr"> {}

export const TextContainer = styled(ButtonText)<TextContainerProps>(
    ({ $px, $pl, $pr }) => css`
        flex-grow: 1;
        min-width: 0;

        ${resolveResponsiveProperties(BREAKPOINTS, {
            paddingLeft: formatResponsiveValue($pl ?? $px),
            paddingRight: formatResponsiveValue($pr ?? $px),
        })};
    `,
);
