import { config } from "@config/config";
import { defaultMetaTagData } from "@config/defaultMetaTagData";
import Head from "next/head";
import { useRouter } from "next/router";
import { FC, PropsWithChildren, memo } from "react";

export interface MetaTagDataProps {
    canonicalUrl?: string;
    image?: string;
    keywords?: string;
    metaDescription?: string;
    metaRobots?:
        | "index"
        | "noindex"
        | "follow"
        | "nofollow"
        | "index, follow"
        | "index, nofollow"
        | "noindex, follow"
        | "noindex, nofollow";
    metaTitle?: string;
    preloadImgUrl?: string | null;
    replaceFullTitle?: boolean;
    title: string;
    twitterCard?: string;
    twitterImageAlt?: string;
    twitterUserName?: string;
    type?: "article" | "blog" | "profile" | "website";
}

// eslint-disable-next-line complexity
const MetaTagDataComponent: FC<PropsWithChildren<MetaTagDataProps>> = (props) => {
    const {
        canonicalUrl,
        image,
        keywords,
        metaDescription,
        metaRobots,
        metaTitle,
        preloadImgUrl,
        replaceFullTitle,
        title,
        twitterCard,
        twitterImageAlt,
        twitterUserName,
        type,
    } = props;

    const { asPath } = useRouter();
    const canonicalLink = config.FRONTEND_URL + asPath.split("?")[0].replace(/\/$/, "");

    return (
        <Head>
            <title>{replaceFullTitle ? title : (title ? `${title} | ` : "") + defaultMetaTagData.siteName}</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
            <meta name="title" content={metaTitle ?? title} />
            <meta name="description" content={metaDescription ?? defaultMetaTagData.metaDescription} />
            {(keywords || defaultMetaTagData.keywords) && (
                <meta name="keywords" content={keywords ?? defaultMetaTagData.keywords} />
            )}
            <meta property="og:title" content={metaTitle ?? defaultMetaTagData.metaTitle} />
            <meta property="og:description" content={metaDescription ?? defaultMetaTagData.metaDescription} />
            {(image || defaultMetaTagData.image) && (
                <>
                    <meta property="og:image" content={`${config.FRONTEND_URL}${image ?? defaultMetaTagData.image}`} />
                    <meta name="twitter:image" content={`${config.FRONTEND_URL}${image ?? defaultMetaTagData.image}`} />
                </>
            )}
            <meta property="og:image:width" content={String(defaultMetaTagData.imageWidth)} />
            <meta property="og:image:height" content={String(defaultMetaTagData.imageHeight)} />
            <meta property="og:url" content={canonicalUrl ?? config.FRONTEND_URL + asPath} />
            <meta property="og:site_name" content={defaultMetaTagData.siteName} />
            {defaultMetaTagData.fbAppId && <meta property="fb:app_id" content={defaultMetaTagData.fbAppId} />}
            <meta property="og:type" content={type ?? defaultMetaTagData.type} />
            {twitterCard && <meta name="twitter:card" content={twitterCard} />}
            {twitterImageAlt && <meta name="twitter:image:alt" content={twitterImageAlt} />}
            {twitterUserName && <meta name="twitter:site" content={twitterUserName} />}
            {metaRobots && <meta name="robots" content={metaRobots} />}
            <link rel="canonical" href={canonicalUrl ?? canonicalLink} />
            {preloadImgUrl && <link rel="preload" href={preloadImgUrl} as="image" />}
        </Head>
    );
};

export const MetaTagData = memo(MetaTagDataComponent);

MetaTagData.displayName = "MetaTagData";
