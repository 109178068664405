import { BREAKPOINTS, Breakpoints } from "@ui/theme/breakpoints";
import { BoxElements } from "@ui/types/elements";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { responsiveHidden } from "@uxf/styles/mixins/responsiveHidden";
import { responsiveHideVisually } from "@uxf/styles/mixins/responsiveHideVisually";
import { Borders } from "@uxf/styles/properties/borders";
import { Breaks } from "@uxf/styles/properties/breaks";
import { Child } from "@uxf/styles/properties/child";
import { Colors } from "@uxf/styles/properties/colors";
import { Column } from "@uxf/styles/properties/column";
import { FlexParent } from "@uxf/styles/properties/flexParent";
import { Overflow } from "@uxf/styles/properties/overflow";
import { Paragraph } from "@uxf/styles/properties/paragraph";
import { Position } from "@uxf/styles/properties/position";
import { Shadows } from "@uxf/styles/properties/shadows";
import { Sizing } from "@uxf/styles/properties/sizing";
import { Spacings } from "@uxf/styles/properties/spacings";
import { Visibility } from "@uxf/styles/properties/visibility";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import { HTMLAttributes } from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

export interface FlexBoxProps
    extends Child,
        Colors,
        Column,
        FlexParent,
        Omit<Position, "$verticalAlign">,
        Omit<Visibility<Breakpoints>, "$backfaceVisibility">,
        Overflow,
        Pick<Borders, "$border" | "$borderRadius">,
        Pick<Breaks, "$breakInside">,
        Pick<Shadows, "$boxShadow">,
        Pick<Paragraph<Breakpoints>, "$textAlign">,
        Spacings,
        Sizing,
        HTMLAttributes<HTMLHeadingElement> {
    as?: keyof Pick<JSX.IntrinsicElements, BoxElements | "span">;
    $css?: FlattenSimpleInterpolation;
}

export const FlexBox = styled("div")<FlexBoxProps>(
    ({
        $alignContent,
        $alignItems,
        $alignSelf,
        $backgroundColor,
        $border,
        $borderRadius,
        $bottom,
        $boxShadow,
        $breakInside,
        $color,
        $columnCount,
        $columnFill,
        $columnGap,
        $columnRule,
        $columnSpan,
        $columnWidth,
        $css,
        $display = "flex",
        $flexBasis,
        $flexDirection,
        $flexGrow,
        $flexShrink,
        $flexWrap,
        $gridArea,
        $height,
        $hidden,
        $hideVisually,
        $justifyContent,
        $justifySelf,
        $left,
        $maxHeight,
        $maxWidth,
        $mb,
        $minHeight,
        $minWidth = 0,
        $ml,
        $mr,
        $mt,
        $mx,
        $my,
        $opacity,
        $order,
        $overflow,
        $overflowX,
        $overflowY,
        $pb,
        $pl,
        $position,
        $pr,
        $pt,
        $px,
        $py,
        $right,
        $textAlign,
        $top,
        $width,
        $zIndex,
    }) => css`
        ${resolveResponsiveProperties(BREAKPOINTS, {
            alignContent: formatResponsiveValue($alignContent),
            alignItems: formatResponsiveValue($alignItems),
            alignSelf: formatResponsiveValue($alignSelf),
            backgroundColor: formatResponsiveValue($backgroundColor),
            border: formatResponsiveValue($border),
            borderRadius: formatResponsiveValue($borderRadius),
            bottom: formatResponsiveValue($bottom),
            boxShadow: formatResponsiveValue($boxShadow),
            breakInside: formatResponsiveValue($breakInside),
            color: formatResponsiveValue($color),
            columnCount: formatResponsiveValue($columnCount, true),
            columnFill: formatResponsiveValue($columnFill),
            columnGap: formatResponsiveValue($columnGap),
            columnRule: formatResponsiveValue($columnRule),
            columnSpan: formatResponsiveValue($columnSpan),
            columnWidth: formatResponsiveValue($columnWidth),
            display: formatResponsiveValue($display),
            flexBasis: formatResponsiveValue($flexBasis),
            flexDirection: formatResponsiveValue($flexDirection),
            flexGrow: formatResponsiveValue($flexGrow, true),
            flexShrink: formatResponsiveValue($flexShrink, true),
            flexWrap: formatResponsiveValue($flexWrap),
            gridArea: formatResponsiveValue($gridArea),
            height: formatResponsiveValue($height),
            justifyContent: formatResponsiveValue($justifyContent),
            justifySelf: formatResponsiveValue($justifySelf),
            left: formatResponsiveValue($left),
            marginBottom: formatResponsiveValue($mb ?? $my),
            marginLeft: formatResponsiveValue($ml ?? $mx),
            marginRight: formatResponsiveValue($mr ?? $mx),
            marginTop: formatResponsiveValue($mt ?? $my),
            maxHeight: formatResponsiveValue($maxHeight),
            maxWidth: formatResponsiveValue($maxWidth),
            minHeight: formatResponsiveValue($minHeight),
            minWidth: formatResponsiveValue($minWidth),
            opacity: formatResponsiveValue($opacity, true),
            order: formatResponsiveValue($order, true),
            overflow: formatResponsiveValue($overflow),
            overflowX: formatResponsiveValue($overflowX),
            overflowY: formatResponsiveValue($overflowY),
            paddingBottom: formatResponsiveValue($pb ?? $py),
            paddingLeft: formatResponsiveValue($pl ?? $px),
            paddingRight: formatResponsiveValue($pr ?? $px),
            paddingTop: formatResponsiveValue($pt ?? $py),
            position: formatResponsiveValue($position),
            right: formatResponsiveValue($right),
            textAlign: formatResponsiveValue($textAlign),
            top: formatResponsiveValue($top),
            width: formatResponsiveValue($width),
            zIndex: formatResponsiveValue($zIndex, true),
        })};

        ${responsiveHideVisually(BREAKPOINTS, $hideVisually)};
        ${responsiveHidden(BREAKPOINTS, $hidden)};

        ${injectCss($css)};
    `,
);
