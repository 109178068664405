import { COLORS } from "@ui/theme/colors";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Button, ButtonProps } from "./Button";

export type ButtonOutlinedGreenProps = Omit<ButtonProps, "$outline">;

const ButtonOutlinedGreenComponent: ForwardRefRenderFunction<HTMLAnchorElement, ButtonOutlinedGreenProps> = (
    props,
    ref,
) => (
    <Button
        $outline
        $color={COLORS.green.default}
        $outlineBackgroundHoverColor={COLORS.green.secondaryDark}
        $textHoverColor={COLORS.green.default}
        $textTransform="uppercase"
        ref={ref}
        {...props}
    />
);

export const ButtonOutlinedGreen = forwardRef(ButtonOutlinedGreenComponent);

ButtonOutlinedGreen.displayName = "ButtonOutlinedGreen";
