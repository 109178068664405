import { Clickable, ClickableProps } from "@ui/components/Clickable/Clickable";
import { cover } from "@ui/mixins/cover";
import { disabledElementStyle } from "@ui/mixins/disabledElementStyle";
import { focusShadow } from "@ui/mixins/focusShadow";
import { COLORS } from "@ui/theme/colors";
import { CLASSES } from "@uxf/core/constants/classes";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { ColorProperty } from "@uxf/styles/types";
import { transition } from "@uxf/styles/utils/transition";
import { Property } from "csstype";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

export interface StyledClickableProps extends ClickableProps {
    $backgroundColor?: ColorProperty;
    $backgroundHoverColor?: ColorProperty;
    $color?: ColorProperty;
    $cursor?: Property.Cursor;
    $css?: FlattenSimpleInterpolation;
    $focusColor?: ColorProperty;
    $hoverColor?: ColorProperty;
    $offset?: number;
}

export const StyledClickable = styled(Clickable)<StyledClickableProps>(
    ({
        $backgroundColor,
        $backgroundHoverColor = COLORS.green.secondaryDark,
        $color = COLORS.green.default,
        $cursor = "pointer",
        $css,
        $focusColor,
        $hoverColor,
        $offset = 8,
    }) => css`
        ${cover($offset ? -$offset : 0)};
        align-items: center;
        background-color: ${$backgroundColor};
        border-radius: 50%;
        color: ${$color};
        display: flex;
        justify-content: center;
        transition: ${transition(["backgroundColor", "color"], 100)};

        &:focus {
            outline: none;
        }

        &.${CLASSES.IS_HOVERABLE} {
            cursor: ${$cursor};

            &:active,
            &:hover {
                background-color: ${$backgroundHoverColor};
                color: ${$hoverColor};
            }
        }

        &.${CLASSES.FOCUS_VISIBLE} {
            ${focusShadow($focusColor ?? $color)};
        }

        &.${CLASSES.IS_DISABLED} {
            ${disabledElementStyle};
        }

        &.${CLASSES.IS_LOADING} {
            cursor: progress;
        }

        ${injectCss($css)}
    `,
);
