import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { FlashMessagesService } from "./FlashMessagesService";
import { t } from "./TranslationService";

interface ErrorMessages {
    title?: string | null;
    body?: string | null;
}

const warningMessageTypes: string[] = [
    "api.vehicleInsurance.orderInProcessByBackOffice",
    "api.vehicleInsurance.selectedOfferNotFound",
];

const getGraphQLErrorMessage = (e: ApolloError): ErrorMessages => {
    if (e.networkError) {
        return { title: t("error.network") };
    }

    // watch wolf already exists
    if (e.graphQLErrors[0].extensions.code === "WATCH_WOLF_ALREADY_EXISTS") {
        return { title: t("error.watchWolfAlreadyExists") };
    }

    // watch wolf already exists
    if (e.graphQLErrors[0].extensions.code === "WATCH_WOLF_FORBIDDEN") {
        return { title: t("error.watchWolfForbidden") };
    }

    // bad user input
    if (e.graphQLErrors[0].extensions.code === "BAD_USER_INPUT" && Array.isArray(e.graphQLErrors[0].extensions.info)) {
        return { title: e.graphQLErrors[0].extensions.info.map((i) => i.message).join(", "), body: null };
    }

    const errorMessageName = e.graphQLErrors[0].message;
    const title = t(errorMessageName as any);
    const body = t(`${errorMessageName}.body` as any);

    if (title === errorMessageName) {
        return {};
    }

    return { title, body: body !== `${errorMessageName}.body` ? body : null };
};

const getErrorMessage = (e: any): ErrorMessages => {
    if (e instanceof ApolloError) {
        return getGraphQLErrorMessage(e);
    }

    return {};
};

const logError = (e: any): void => {
    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry.captureException(e);
    } else {
        console.warn("Error handler: ", e); // eslint-disable-line no-console
    }
};

const handleError = (e: any, showFlashMessage = true): void => {
    const errorName = e?.message;
    const { title, body } = getErrorMessage(e);

    if (!title) {
        logError(e);
    }
    if (showFlashMessage) {
        FlashMessagesService.flashMessage({
            variant: warningMessageTypes.includes(errorName) ? "warning" : "error",
            title: title || t("error.unknown"),
            body,
        });
    }
};

export const ErrorService = {
    logError,
    getErrorMessage,
    handleError,
};
