import { cover } from "@ui/mixins/cover";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { Colors } from "@uxf/styles/properties/colors";
import { Paddings } from "@uxf/styles/properties/paddings";
import { ResponsiveProperty } from "@uxf/styles/types";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export interface LoaderRootProps extends HTMLAttributes<HTMLDivElement>, Colors, Paddings {
    $absolute?: boolean;
    $minHeight?: ResponsiveProperty.MinHeight;
}

export const LoaderRoot = styled("span")<LoaderRootProps>(
    ({ $absolute, $backgroundColor, $color, $minHeight, $pb, $pl, $pr, $pt, $py, $px = 4 }) => css`
        ${$absolute && cover()};
        align-items: center;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        overflow: hidden;
        position: ${!$absolute ? "relative" : null};
        text-align: center;
        width: 100%;

        ${resolveResponsiveProperties(BREAKPOINTS, {
            backgroundColor: formatResponsiveValue($backgroundColor),
            color: formatResponsiveValue($color),
            minHeight: $absolute ? "none" : formatResponsiveValue($minHeight),
            paddingBottom: formatResponsiveValue($pb ?? $py),
            paddingLeft: formatResponsiveValue($pl ?? $px),
            paddingRight: formatResponsiveValue($pr ?? $px),
            paddingTop: formatResponsiveValue($pt ?? $py),
        })};
    `,
);
