import { COLORS } from "@ui/theme/colors";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Button, ButtonProps } from "./Button";

type ButtonOpaqueProps = Omit<ButtonProps, "$outline" | "$outlineBackgroundColor" | "$outlineBackgroundHoverColor">;

const ButtonOpaqueComponent: ForwardRefRenderFunction<HTMLAnchorElement, ButtonOpaqueProps> = (props, ref) => (
    <Button
        $color="transparent"
        $height="none"
        $fontSize={12}
        $focusColor={COLORS.green.default}
        $hoverColor={COLORS.green.secondaryDark}
        $px={8}
        $textColor={COLORS.green.default}
        $textTransform="uppercase"
        $underline
        ref={ref}
        {...props}
    />
);

export const ButtonOpaque = forwardRef(ButtonOpaqueComponent);

ButtonOpaque.displayName = "ButtonOpaque";
