import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { COLORS } from "@ui/theme/colors";
import { ZINDEXES } from "@ui/theme/zIndexes";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { facepaintMin } from "@uxf/styles/utils/responsive";
import { transparentize } from "color2k";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export interface RootProps extends HTMLAttributes<HTMLDivElement> {
    $dark?: boolean;
    $zIndex?: number;
}

export const Root = styled("div")<RootProps>(
    ({ $dark, $zIndex }) => css`
        align-items: center;
        inset: 0;
        display: flex;
        justify-content: center;
        overflow-y: auto;
        position: fixed;
        z-index: ${ZINDEXES.modal + ($zIndex ? $zIndex : 0)};

        ${facepaintMin(BREAKPOINTS)({
            backgroundColor: formatResponsiveValue([
                $dark ? COLORS.green.default : COLORS.white,
                null,
                transparentize(COLORS.black, 0.5),
            ]),
        })}

        &:focus {
            outline: none;
        }
    `,
);
