export const TYPOGRAPHY = {
    fontFamily: {
        default: "var(--default-font-family)",
    },
    fontWeight: {
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    fontStyle: {
        normal: "normal",
        italic: "italic",
    },
};
