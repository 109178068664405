import { COLORS } from "@ui/theme/colors";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Button, ButtonProps } from "./Button";

type ButtonYellowProps = Omit<ButtonProps, "$outline" | "$outlineBackgroundColor" | "$outlineBackgroundHoverColor">;

const ButtonYellowComponent: ForwardRefRenderFunction<HTMLAnchorElement, ButtonYellowProps> = (props, ref) => (
    <Button
        $color={COLORS.yellow.default}
        $hoverColor={COLORS.yellow.dark}
        $textColor={COLORS.green.default}
        $textTransform="uppercase"
        ref={ref}
        {...props}
    />
);

export const ButtonYellow = forwardRef(ButtonYellowComponent);

ButtonYellow.displayName = "ButtonYellow";
