import { CookieConsentFormContainer } from "@shared/components/cookie-consent/cookie-consent-form-container";
import { useBackofficeProfile } from "@shared/hooks/useBackofficeProfile";
import { createCookieConsentData } from "@shared/utils/cookieConsent/create-cookie-consent-data";
import { setCookieConsent } from "@shared/utils/cookieConsent/set-cookie-consent";
import { ButtonGreen } from "@ui/components/Button/ButtonGreen";
import { ButtonOutlinedGreen } from "@ui/components/Button/ButtonOutlinedGreen";
import { IconLink } from "@ui/components/IconLink/IconLink";
import { Wolf } from "@ui/components/Wolf/Wolf";
import { useModal } from "@ui/hooks/useModal";
import { COLORS } from "@ui/theme/colors";
import { memo, useCallback, useRef } from "react";
import { css } from "styled-components";
import styles from "./cookie-consent-bar.module.css";

export type CookieConsentBarProps = {
    onClose: () => void;
};

export const CookieConsentBar = memo<CookieConsentBarProps>(({ onClose }) => {
    const backofficeProfile = useBackofficeProfile();
    const [setModalContent, ModalProvider, closeModal] = useModal();

    const scrollRef = useRef<HTMLDivElement>(null);

    const openPreferences = useCallback(() => {
        setModalContent(
            <CookieConsentFormContainer
                closeModal={() => {
                    closeModal();
                    onClose();
                }}
            />,
        );
    }, [closeModal, onClose, setModalContent]);

    const onAcceptAll = useCallback(() => {
        setCookieConsent(createCookieConsentData(true));
        onClose();
    }, [onClose]);

    const onDenyAll = useCallback(() => {
        setCookieConsent(createCookieConsentData(false));
        onClose();
    }, [onClose]);

    if (backofficeProfile) {
        return null;
    }

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <div className={styles.root} tabIndex={0}>
                <div className="relative flex max-h-screen overflow-auto bg-yellow xxl:max-h-[273px]" ref={scrollRef}>
                    <div className="absolute right-6 top-6">
                        <IconLink
                            $color={COLORS.green.default}
                            iconSize={16}
                            name="cross"
                            onClick={onDenyAll}
                            size={24}
                            title="Zavřít"
                        />
                    </div>
                    <div className="relative hidden w-[360px] shrink-0 items-center justify-center overflow-hidden xxl:flex">
                        <div className="absolute left-[-240px] top-[calc(50%_-_300px)] size-[600px] rounded-full bg-white" />
                        <Wolf className="relative -ml-5 -mt-3 w-[160px]" name="cookies" />
                    </div>
                    <div className="flex w-full flex-col px-4 pb-3 pt-6 text-green sm:pt-4 md:pb-6 md:pl-10 md:pr-8 md:pt-8">
                        <h2 className="ui-typo-h2 mb-2 mr-8 max-sm:text-24 sm:mr-0">I&nbsp;my používáme cookies</h2>
                        <p className="ui-typo-body mb-2 md:mb-4">
                            Díky nim jsou naše stránky bezpečnější a&nbsp;nabízí co možná nejlepší uživatelský zážitek.
                            <span className="max-sm:hidden">
                                {" "}
                                Cookies nám dále pomáhají pochopit, co Vás na&nbsp;našich stránkách zajímá a&nbsp;co
                                bychom měli vylepšit. Tyto informace můžeme poskytnout i&nbsp;naším obchodním partnerům,
                                aby Vás např. neobtěžovali nerelevantní reklamou.
                            </span>
                        </p>
                        <div className="mt-auto flex flex-col justify-end sm:flex-row">
                            <div className="mb-1 mt-auto flex w-full sm:mr-4 sm:w-auto">
                                <ButtonOutlinedGreen
                                    $css={css`
                                        flex: 1 0 auto;
                                    `}
                                    $minWidth={[null, null, null, 224]}
                                    $mr={[8, 16]}
                                    $mb={[4, 0]}
                                    onClick={openPreferences}
                                >
                                    Nastavit
                                </ButtonOutlinedGreen>
                                <ButtonOutlinedGreen
                                    $css={css`
                                        flex: 1 0 auto;
                                    `}
                                    $minWidth={[null, null, null, 224]}
                                    onClick={onDenyAll}
                                >
                                    Nesouhlasím
                                </ButtonOutlinedGreen>
                            </div>
                            <ButtonGreen $minWidth={[null, null, null, 224]} onClick={onAcceptAll}>
                                Souhlasím
                            </ButtonGreen>
                        </div>
                    </div>
                </div>
            </div>
            <ModalProvider />
        </>
    );
});

CookieConsentBar.displayName = "CookieConsentBar";
