/* eslint-disable */
/* tslint:disable */
/* prettier-ignore */
export const translations = {
    "_general.beforeXDays": {
        "cs": {
            "zero": "dnes",
            "one": "včera",
            "few": "před {{count}} dny",
            "other": "před {{count}} dny"
        }
    },
    "_general.countOfDays": {
        "cs": {
            "zero": "dní",
            "one": "den",
            "few": "dny",
            "other": "dní"
        }
    },
    "_general.countOfInsurances": {
        "cs": {
            "zero": "pojistek",
            "one": "pojistku",
            "few": "pojistky",
            "other": "pojistek"
        }
    },
    "_general.countOfLoans": {
        "cs": {
            "zero": "půjček",
            "one": "půjčku",
            "few": "půjčky",
            "other": "půjček"
        }
    },
    "_general.countOfMonths": {
        "cs": {
            "zero": "měsíců",
            "one": "měsíc",
            "few": "měsíce",
            "other": "měsíců"
        }
    },
    "_general.countOfOffers": {
        "cs": {
            "zero": "nabídek",
            "one": "nabídku",
            "few": "nabídky",
            "other": "nabídek"
        }
    },
    "_general.countOfPassengers": {
        "cs": {
            "zero": "cestující",
            "one": "cestující",
            "few": "cestující",
            "other": "cestujících"
        }
    },
    "_general.countOfTariffs": {
        "cs": {
            "zero": "tarifů",
            "one": "tarif",
            "few": "tarify",
            "other": "tarifů"
        }
    },
    "_general.countOfVariants": {
        "cs": {
            "zero": "variant",
            "one": "variantu",
            "few": "varianty",
            "other": "variant"
        }
    },
    "_general.countOfYears": {
        "cs": {
            "zero": "let",
            "one": "rok",
            "few": "roky",
            "other": "let"
        }
    },
    "_general.lengthOfCommitment": {
        "cs": {
            "zero": "Smlouva na dobu neurčitou",
            "one": "Smlouva na 1 měsíc",
            "few": "Smlouva na {{count}} měsíce",
            "other": "Smlouva na {{count}} měsíců"
        }
    },
    "_general.lengthOfCommitmentShort": {
        "cs": {
            "zero": "na dobu neurčitou",
            "one": "1 měsíc",
            "few": "{{count}} měsíce",
            "other": "{{count}} měsíců"
        }
    },
    "_general.lengthOfFixation": {
        "cs": {
            "zero": "Bez fixace ceny",
            "one": "Fixace ceny na 1 měsíc",
            "few": "Fixace ceny na {{count}} měsíce",
            "other": "Fixace ceny na {{count}} měsíců"
        }
    },
    "_general.lengthOfFixationShort": {
        "cs": {
            "zero": "bez fixace ceny",
            "one": "1 měsíc",
            "few": "{{count}} měsíce",
            "other": "{{count}} měsíců"
        }
    },
    "_general.others": {
        "cs": {
            "zero": "další",
            "one": "další",
            "few": "dalších",
            "other": "dalších"
        }
    },
    "api.admin.cannotCreateProfileForNotRegisteredProfile": {
        "cs": ""
    },
    "api.admin.centropol.emptyCredentials": {
        "cs": ""
    },
    "api.admin.centropol.partnerMapping": {
        "cs": ""
    },
    "api.admin.egergo.partnerApiNotSupported": {
        "cs": ""
    },
    "api.admin.email.invalidFileFormat": {
        "cs": "Špatný formát souboru zkontrolujte si ho podle nápovědy"
    },
    "api.admin.energo.birthDateIsNull": {
        "cs": ""
    },
    "api.admin.energo.endOfCommitmentType": {
        "cs": ""
    },
    "api.admin.energo.invalidBreaker": {
        "cs": ""
    },
    "api.admin.energo.invalidContractType": {
        "cs": ""
    },
    "api.admin.energo.invalidMethodOfSendingContract": {
        "cs": ""
    },
    "api.admin.energo.invalidPaymentFrequency": {
        "cs": ""
    },
    "api.admin.energo.invalidPreferredPaymentMethod": {
        "cs": ""
    },
    "api.admin.energo.invalidRefundMethod": {
        "cs": ""
    },
    "api.admin.energo.invalidSendInvoiceMethod": {
        "cs": ""
    },
    "api.admin.energo.missingContactAddress": {
        "cs": ""
    },
    "api.admin.energo.missingEonIdValues": {
        "cs": ""
    },
    "api.admin.energo.missingSupplyAddress": {
        "cs": ""
    },
    "api.admin.energo.partnerMapping": {
        "cs": ""
    },
    "api.admin.energo.unsupportedEonContractType": {
        "cs": "Do partnerského portálu lze odeslat pouze změnu dodavatele"
    },
    "api.admin.energoKalkulator.combinationNotFound": {
        "cs": ""
    },
    "api.admin.inaccurateAddress": {
        "cs": ""
    },
    "api.admin.mnd.birthDateIsNull": {
        "cs": ""
    },
    "api.admin.mnd.invalidContractType": {
        "cs": ""
    },
    "api.admin.mnd.invalidMethodOfSendingContract": {
        "cs": ""
    },
    "api.admin.mnd.invalidPaymentFrequency": {
        "cs": ""
    },
    "api.admin.mnd.invalidPreferredPaymentMethod": {
        "cs": ""
    },
    "api.admin.mnd.invalidSendInvoiceMethod": {
        "cs": ""
    },
    "api.admin.mnd.missingContactAddress": {
        "cs": ""
    },
    "api.admin.mnd.missingSupplyAddress": {
        "cs": ""
    },
    "api.admin.userInvitation.duplicityEmail": {
        "cs": "Uživatel s tímto emailem už je zaregistrován."
    },
    "api.auth.duplicityEmail": {
        "cs": "E-mail již existuje."
    },
    "api.auth.emailNotRegistered": {
        "cs": "Zadaný e-mail není registrován."
    },
    "api.auth.login.invalidCredentials": {
        "cs": ""
    },
    "api.auth.login.invalidMagicLink": {
        "cs": ""
    },
    "api.auth.userAlreadyRegistered": {
        "cs": "Profil je již registrován."
    },
    "api.benefit.alreadyExists": {
        "cs": ""
    },
    "api.calculation.invalid": {
        "cs": ""
    },
    "api.calculation.locked": {
        "cs": "Kalkulaci nelze upravit."
    },
    "api.calculation.notSupported": {
        "cs": "Typ kalkulace není podporován."
    },
    "api.calculation.withoutEmail": {
        "cs": "Do kalkulace je nutné doplnit email"
    },
    "api.calculation.withoutPhone": {
        "cs": "Do kalkulace je nutné doplnit telefon"
    },
    "api.core.addressNotFound": {
        "cs": "Adresa nenalezena."
    },
    "api.core.forbidden": {
        "cs": "Přístup zamítnut."
    },
    "api.core.invalidInput": {
        "cs": ""
    },
    "api.core.notFound": {
        "cs": "Stránka nenalezena"
    },
    "api.core.unauthorized": {
        "cs": "Vyžadováno přihlášení"
    },
    "api.energoKalkulator.combinationNotFound": {
        "cs": "Kombinace pro kalkulaci nenalezena"
    },
    "api.energoKalkulator.resultItemNotFound": {
        "cs": ""
    },
    "api.finance.combinationNotFound": {
        "cs": ""
    },
    "api.finance.csasApiError": {
        "cs": ""
    },
    "api.finance.csasApiRefused": {
        "cs": "Partner odmítl poptávku"
    },
    "api.finance.leadAlreadyCreated": {
        "cs": "Kalkulace je ve stavu poptávky. Nelze ji upravovat."
    },
    "api.landingPage.emailAlreadyRegistered": {
        "cs": "E-mail je již registrován"
    },
    "api.marketing.coefficientNotFound": {
        "cs": ""
    },
    "api.marketing.coefficientOverlap": {
        "cs": ""
    },
    "api.propertyInsurance.calculationNotTriggered": {
        "cs": ""
    },
    "api.propertyInsurance.contractAlreadyCreated": {
        "cs": "Kalkulace je ve stavu poptávky. Nelze ji upravovat."
    },
    "api.propertyInsurance.invalidOrderOneClickStatus": {
        "cs": ""
    },
    "api.propertyInsurance.orderCancelled": {
        "cs": ""
    },
    "api.propertyInsurance.orderInProcessByBackOffice": {
        "cs": ""
    },
    "api.propertyInsurance.zeteoCalculationNotCreated": {
        "cs": ""
    },
    "api.storage.overLimit": {
        "cs": ""
    },
    "api.ticket.noAvailable": {
        "cs": ""
    },
    "api.ticket.urgencySmsSent": {
        "cs": ""
    },
    "api.travelInsurance.contractAlreadyCreated": {
        "cs": ""
    },
    "api.travelInsurance.finishOrderNotTriggered": {
        "cs": ""
    },
    "api.travelInsurance.insuranceIntervalTooLong": {
        "cs": ""
    },
    "api.travelInsurance.invalidOrderStatus": {
        "cs": ""
    },
    "api.travelInsurance.noSelectedOffer": {
        "cs": ""
    },
    "api.travelInsurance.orderCancelled": {
        "cs": ""
    },
    "api.travelInsurance.orderInProcessByBackOffice": {
        "cs": ""
    },
    "api.travelInsurance.orderNotFound": {
        "cs": ""
    },
    "api.travelInsurance.recapitulationNotTriggered": {
        "cs": ""
    },
    "api.vehicleInsurance.calculationNotTriggered": {
        "cs": ""
    },
    "api.vehicleInsurance.contractAlreadyCreated": {
        "cs": ""
    },
    "api.vehicleInsurance.finishOrderNotTriggered": {
        "cs": ""
    },
    "api.vehicleInsurance.invalidOrderStatus": {
        "cs": "Kalkulace je ve stavu poptávky. Nelze ji upravovat."
    },
    "api.vehicleInsurance.invalidVehicleType": {
        "cs": ""
    },
    "api.vehicleInsurance.noSelectedOffer": {
        "cs": ""
    },
    "api.vehicleInsurance.offerNotFoundInRecalculation": {
        "cs": ""
    },
    "api.vehicleInsurance.orderCancelled": {
        "cs": "Objednávka byla zrušena"
    },
    "api.vehicleInsurance.orderCancelled.body": {
        "cs": "Zdá se, že jsme vaši objednávku zrušili. Pokud chcete vědět více, ozvěte se na 253 523 100."
    },
    "api.vehicleInsurance.orderInProcessByBackOffice": {
        "cs": "Právě na tom pracujeme"
    },
    "api.vehicleInsurance.orderInProcessByBackOffice.body": {
        "cs": "Na vaší objednávce nyní pracují naši specialisté - proto ji teď nemůžete změnit. Zkuste to prosím později, nebo nám zavolejte na 253 253 100, spolu to vyřešíme."
    },
    "api.vehicleInsurance.orderNotFound": {
        "cs": ""
    },
    "api.vehicleInsurance.recapitulationNotTriggered": {
        "cs": ""
    },
    "api.vehicleInsurance.selectedOfferNotFound": {
        "cs": "Zdá se, že máte výsledky otevřené ve více oknech"
    },
    "api.vehicleInsurance.selectedOfferNotFound.body": {
        "cs": "Tyto výsledky již nejsou aktuální, protože jste podle všeho otevřeli srovnání v jiném okně nebo jiné záložce. Načtěte prosím stránku znovu, aby se pojistky přepočítaly."
    },
    "api.vehicleInsurance.subjectMissingAddress": {
        "cs": ""
    },
    "api.watchwolf.forCustomersOnly": {
        "cs": "Vytvořte vlka z backoffisu"
    },
    "api.watchwolf.singlePerCalculation": {
        "cs": ""
    },
    "api.zeteo.showOffers": {
        "cs": {
            "zero": "Nebyla nalezena žádná nabídka",
            "one": "Zobrazit 1 nabídku",
            "few": "Zobrazit {{count}} nabídky",
            "other": "Zobrazit {{count}} nabídek"
        }
    },
    "error.network": {
        "cs": "Server není dostupný"
    },
    "error.unknown": {
        "cs": "Neznámá chyba"
    },
    "error.watchWolfAlreadyExists": {
        "cs": "Zadanou RZ vlk již hlídá. Závazek můžete upravit."
    },
    "error.watchWolfForbidden": {
        "cs": "Na RZ existuje smlouva."
    }
}
