import { Clickable, ClickableProps } from "@ui/components/Clickable/Clickable";
import { cover } from "@ui/mixins/cover";
import { focusShadow } from "@ui/mixins/focusShadow";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { BUTTONS } from "@ui/theme/buttons";
import { COLORS } from "@ui/theme/colors";
import { CLASSES } from "@uxf/core/constants/classes";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { Margins, margins } from "@uxf/styles/properties/margins";
import { ColorProperty, ResponsiveProperty } from "@uxf/styles/types";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import { transition } from "@uxf/styles/utils/transition";
import { Property } from "csstype";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import { ContentContainer } from "./ContentContainer";
import { OuterContainer } from "./OuterContainer";

export interface StyledClickableProps extends ClickableProps, Margins {
    $borderRadius?: ResponsiveProperty.BorderRadius;
    $color?: ColorProperty;
    $css?: FlattenSimpleInterpolation;
    $cursor?: Property.Cursor;
    $focusColor?: ColorProperty;
    $height?: ResponsiveProperty.Height;
    $hoverColor?: ColorProperty;
    $maxWidth?: ResponsiveProperty.MaxWidth;
    $minHeight?: ResponsiveProperty.MinHeight;
    $minWidth?: ResponsiveProperty.MinWidth;
    $outline?: boolean;
    $outlineBackgroundColor?: ColorProperty;
    $outlineBackgroundHoverColor?: ColorProperty;
    $textColor?: ColorProperty;
    $textHoverColor?: ColorProperty;
    $underline?: boolean;
}

export const StyledClickable = styled(Clickable)<StyledClickableProps>(
    ({
        $borderRadius = 999,
        $color = COLORS.green.default,
        $css,
        $cursor = "pointer",
        $focusColor,
        $height = [BUTTONS.small, null, BUTTONS.default],
        $hoverColor = COLORS.green.dark,
        $maxWidth,
        $mb,
        $minHeight = "auto",
        $minWidth = 0,
        $ml,
        $mr,
        $mt,
        $mx,
        $my,
        $outline,
        $outlineBackgroundColor,
        $outlineBackgroundHoverColor,
        $textColor,
        $textHoverColor,
        $underline,
    }) => css`
        align-items: stretch;
        background-color: ${$outline ? "transparent" : $color !== "transparent" ? COLORS.white : "transparent"};
        color: ${$textColor ?? ($outline ? $color : COLORS.white)};
        display: inline-flex;
        justify-content: center;
        max-width: 100%;
        text-decoration: ${$underline ? "underline" : "none"};
        user-select: none;

        ${margins({ breakpoints: BREAKPOINTS, $mb, $ml, $mr, $mt, $my, $mx })};

        ${OuterContainer} {
            background-color: ${$outlineBackgroundColor ?? ($outline ? "transparent" : $color)};
            transition: ${transition("backgroundColor", 100)};
        }

        ${$outline &&
        css`
            ${OuterContainer} {
                &::before {
                    ${cover()};
                    border: 2px solid ${$color};
                    border-radius: inherit;
                    content: "";
                    display: block;
                    pointer-events: none;
                }
            }
        `}

        ${resolveResponsiveProperties(BREAKPOINTS, {
            borderRadius: formatResponsiveValue($borderRadius),
            height: formatResponsiveValue($height),
            maxWidth: formatResponsiveValue($maxWidth),
            minHeight: formatResponsiveValue($minHeight),
            minWidth: formatResponsiveValue($minWidth),
        })};

        &:focus {
            outline: none;
        }

        &.${CLASSES.IS_HOVERABLE} {
            cursor: ${$cursor};

            &:active,
            &:hover {
                ${OuterContainer} {
                    background-color: ${$outlineBackgroundHoverColor ?? ($outline ? "transparent" : $hoverColor)};
                }

                color: ${$textHoverColor ?? ($outline ? COLORS.white : undefined)};

                ${$outline &&
                css`
                    ${OuterContainer} {
                        &::before {
                            border-color: ${$hoverColor};
                        }
                    }
                `}
            }
        }

        &.${CLASSES.FOCUS_VISIBLE} {
            ${focusShadow($focusColor ?? $color)};
        }

        &.${CLASSES.IS_DISABLED} {
            pointer-events: none;

            ${OuterContainer} {
                opacity: 0.5;
            }
        }

        &.${CLASSES.IS_LOADING} {
            cursor: progress;

            ${ContentContainer} {
                opacity: 0.25;
            }

            ${OuterContainer} {
                opacity: 1;
            }
        }

        ${injectCss($css)}
    `,
);
