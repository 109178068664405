import { Modal, ModalCloseHandler, ModalProps } from "@ui/components/Modal/Modal";
import { useBodyScrollLock } from "@uxf/core/hooks/useBodyScrollLock";
import { FC, PropsWithChildren, ReactNode, useCallback, useRef, useState } from "react";
import { createPortal } from "react-dom";

export function useModal(
    onModalClose?: ModalCloseHandler,
): [(modalComponent?: ReactNode) => void, FC<PropsWithChildren<ModalProps>>, ModalCloseHandler] {
    const modalRootRef = useRef<HTMLDivElement>(null);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);

    useBodyScrollLock<HTMLDivElement>(modalRootRef, !!modalContent);

    const closeModal = useCallback<ModalCloseHandler>(
        (type) => {
            setModalContent(null);

            if (onModalClose) {
                onModalClose(type);
            }
        },
        [onModalClose],
    );

    const ModalProvider: FC<PropsWithChildren<ModalProps>> = useCallback(
        (props) => {
            return modalContent
                ? createPortal(
                      <Modal handleClose={closeModal} ref={modalRootRef} {...props}>
                          {modalContent}
                      </Modal>,
                      document.body,
                  )
                : null;
        },
        [closeModal, modalContent],
    );

    return [setModalContent, ModalProvider, closeModal];
}
