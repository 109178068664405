import { AnalyticsService } from "@shared/services/AnalyticsService";
import { CommonConstants } from "@shared/utils/CommonConstants";
import { ButtonProps } from "@ui/components/Button/Button";
import { ButtonGreenPrimary } from "@ui/components/Button/ButtonGreenPrimary";
import { ButtonYellow } from "@ui/components/Button/ButtonYellow";
import { Icon } from "@ui/components/Icon/Icon";
import { BUTTONS } from "@ui/theme/buttons";
import { memo, ReactNode } from "react";

interface Props {
    inverse?: boolean;
    title?: ReactNode;
    text?: ReactNode;
    id?: string;
}

export const ContactSection = memo<Props>((props) => {
    const title = props.title ?? <>Jsme tady, abychom vám&nbsp;pomohli.</>;
    const text = props.text ?? (
        <>Rádi vám pomůžeme najít dodavatele, se kterým ušetříte až&nbsp;tisíce korun&nbsp;ročně.</>
    );

    const buttonProps: ButtonProps = {
        $height: [BUTTONS.small, null, BUTTONS.default, null, BUTTONS.extraLarge],
        startIcon: <Icon name="phone" size={[30, null, 36, null, 48]} />,
        $textPl: [12, null, 16, null, 20],
        $fontSize: [16, null, 20, null, 28],
        $pl: [24, null, 30, null, 36],
        $pr: [24, null, 44, null, 60],
    };

    const phoneButtonProps: ButtonProps = {
        ...buttonProps,
        href: `tel:+420${CommonConstants.callbackPhoneNumberRaw}`,
        startIcon: <Icon name="phone" size={[30, null, 36, null, 48]} />,
        $textTransform: "uppercase",
        analyticsCallback: AnalyticsService.uiEvent("Button", "Click", "HP_Footer_Telefon"),
    };

    const mailButtonProps: ButtonProps = {
        ...buttonProps,
        href: "mailto:info@kalkulator.cz",
        startIcon: <Icon name="envelopeAlt" size={[30, null, 36, null, 48]} />,
        $textTransform: "lowercase",
        $mt: 12,
        analyticsCallback: AnalyticsService.uiEvent("Button", "Click", "HP_Footer_Mailto"),
    };

    return (
        <aside className={`py-12 max-md:px-6 xl:py-[100px] ${props.inverse ? "bg-green" : "bg-yellow"}`} id={props.id}>
            <div className="container grid-cols-12 items-center gap-6 md:grid lg:gap-8">
                <div className="col-span-6 lg:col-span-5 lg:col-start-2">
                    <h2 className={`ui-typo-h2 ${props.inverse ? "text-yellow" : "text-green"}`}>{title}</h2>
                    <p className={`ui-typo-h6 mt-6 ${props.inverse ? "text-white" : "text-black"}`}>{text}</p>
                </div>
                <div className="col-span-6 mt-6 flex flex-col items-start md:mt-0 lg:col-span-5">
                    {props.inverse ? (
                        <>
                            <ButtonYellow {...phoneButtonProps}>
                                +420 {CommonConstants.callbackPhoneNumber}
                            </ButtonYellow>
                            <ButtonYellow {...mailButtonProps}>info@kalkulator.cz</ButtonYellow>
                        </>
                    ) : (
                        <>
                            <ButtonGreenPrimary {...phoneButtonProps}>
                                +420 {CommonConstants.callbackPhoneNumber}
                            </ButtonGreenPrimary>
                            <ButtonGreenPrimary {...mailButtonProps}>info@kalkulator.cz</ButtonGreenPrimary>
                        </>
                    )}
                </div>
            </div>
        </aside>
    );
});

ContactSection.displayName = "ContactSection";
