import { Clickable, ClickableProps } from "@ui/components/Clickable/Clickable";
import { disabledElementStyle } from "@ui/mixins/disabledElementStyle";
import { focusShadow } from "@ui/mixins/focusShadow";
import { COLORS } from "@ui/theme/colors";
import { CLASSES } from "@uxf/core/constants/classes";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { ColorProperty } from "@uxf/styles/types";
import { transition } from "@uxf/styles/utils/transition";
import { Property } from "csstype";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

export interface LinkProps extends ClickableProps {
    $color?: ColorProperty;
    $cursor?: Property.Cursor;
    $css?: FlattenSimpleInterpolation;
    $focusColor?: ColorProperty;
    $hoverColor?: ColorProperty;
    $textDecoration?: Property.TextDecoration;
    $fontWeight?: Property.FontWeight;
    $textTransform?: Property.TextTransform;
}

export const Link = styled(Clickable)<LinkProps>(
    ({
        $color = COLORS.green.default,
        $cursor = "pointer",
        $css,
        $focusColor,
        $fontWeight,
        $hoverColor = COLORS.green.dark,
        $textDecoration = "underline",
        $textTransform,
    }) => css`
        color: ${$color};
        font-weight: ${$fontWeight};
        text-decoration: ${$textDecoration};
        text-transform: ${$textTransform};
        transition: ${transition("color", 100)};

        &:focus {
            outline: none;
        }

        &.${CLASSES.IS_HOVERABLE} {
            cursor: ${$cursor};

            &:active,
            &:hover {
                color: ${$hoverColor};
            }
        }

        &.${CLASSES.FOCUS_VISIBLE} {
            ${focusShadow($focusColor ?? $color)};
        }

        &.${CLASSES.IS_DISABLED} {
            ${disabledElementStyle};
        }

        &.${CLASSES.IS_LOADING} {
            cursor: progress;
        }

        ${injectCss($css)}
    `,
);
