import { IconsSet } from "@config/icons";
import { FlexBox } from "@ui/components/FlexBox/FlexBox";
import { Icon, IconProps } from "@ui/components/Icon/Icon";
import { Margins } from "@uxf/styles/properties/margins";
import { ResponsiveProperty } from "@uxf/styles/types";
import { ForwardRefRenderFunction, ReactNode, forwardRef } from "react";
import { StyledClickable, StyledClickableProps } from "./styles/StyledClickable";

export interface IconLinkProps extends Omit<StyledClickableProps, "title">, Margins {
    iconSize?: IconProps["size"];
    name: IconsSet;
    size?: ResponsiveProperty.Width;
    title: ReactNode;
}

const IconLinkComponent: ForwardRefRenderFunction<HTMLAnchorElement, IconLinkProps> = (props, ref) => {
    const { iconSize, $mb, $ml, $mr, $mt, $mx, $my, name, size = 24, title, ...restProps } = props;

    return (
        <FlexBox
            $display="inline-flex"
            $flexShrink={0}
            $height={size}
            $mb={$mb}
            $ml={$ml}
            $mr={$mr}
            $mt={$mt}
            $mx={$mx}
            $my={$my}
            $width={size}
            as="span"
        >
            <span className="relative size-full min-w-0">
                <StyledClickable ref={ref} {...restProps}>
                    <span className="sr-only">{title}</span>
                    <Icon name={name} size={iconSize ?? size} />
                </StyledClickable>
            </span>
        </FlexBox>
    );
};

export const IconLink = forwardRef(IconLinkComponent);

IconLink.displayName = "IconLink";
