import { config } from "@config/config";

export const defaultMetaTagData = {
    metaTitle: "Kalkulátor.cz",
    metaDescription: `My počítáme, vy šetříte. Nejvýhodnější ceny energií a pojištění ${new Date().getFullYear()}`,
    fbAppId: config.FACEBOOK_APP_ID,
    image: "/static/images/og/default-og-image.jpg" /* (relative eg. "/static/fb-og-image.jpg") */,
    keywords: "",
    siteName: "Kalkulátor.cz",
    type: "website",
    imageWidth: 1200,
    imageHeight: 630,
};
