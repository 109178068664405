import { Box } from "@ui/components/Box/Box";
import { ButtonOpaque } from "@ui/components/Button/ButtonOpaque";
import { Icon } from "@ui/components/Icon/Icon";
import { IconLink } from "@ui/components/IconLink/IconLink";
import { ScrollElementContext } from "@ui/contexts/scrollElementContext";
import { COLORS } from "@ui/theme/colors";
import { RADII } from "@ui/theme/radii";
import { SHADOWS } from "@ui/theme/shadows";
import { useWindowSize } from "@uxf/core/hooks/useWindowSize";
import { composeRefs } from "@uxf/core/utils/composeRefs";
import { rem } from "@uxf/styles/utils/rem";
import { forwardRef, MouseEventHandler, useCallback, useContext, useEffect, useRef } from "react";
import { Root, RootProps } from "./styles/Root";

type ModalCloseType = "action" | "no-action";

export type ModalCloseHandler = (type?: ModalCloseType) => void;

export interface ModalProps extends RootProps {
    dark?: boolean;
    disableBackdropClick?: boolean;
    disableCloseButton?: boolean;
    handleClose?: ModalCloseHandler;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
    const { children, dark, disableBackdropClick, disableCloseButton, handleClose, $zIndex, ...restProps } = props;

    const scrollRef = useRef<HTMLDivElement>(null);
    const [scrollElement, setScrollElement] = useContext(ScrollElementContext);

    useEffect(() => {
        const node = scrollRef.current;
        if (node && setScrollElement && scrollElement === window) {
            setScrollElement(node);
        }
    }, [scrollElement, setScrollElement]);

    const handleCloseModal = useCallback(
        (type: ModalCloseType) => () => {
            if (handleClose) {
                handleClose(type);
            }
            if (setScrollElement && scrollElement !== window) {
                setScrollElement(window);
            }
        },
        [handleClose, scrollElement, setScrollElement],
    );

    const size = useWindowSize();

    const onClickHandler = useCallback<MouseEventHandler<HTMLDivElement>>((e) => e.stopPropagation(), []);

    return (
        <Root
            $dark={dark}
            $zIndex={$zIndex}
            onClick={!disableBackdropClick ? handleCloseModal("no-action") : undefined}
            tabIndex={0}
            {...restProps}
        >
            <Box
                $backgroundColor={dark ? COLORS.green.default : COLORS.white}
                $borderRadius={[0, null, RADII.medium]}
                $height={[size?.height ?? "100vh", null, "auto"]}
                $position="relative"
                onClick={onClickHandler}
                ref={composeRefs(ref, scrollRef)}
                $overflow="auto"
                data-body-scroll-lock-ignore
            >
                {!disableCloseButton && (
                    <>
                        <Box
                            $backgroundColor={COLORS.white}
                            $boxShadow={SHADOWS.shadow3}
                            $hidden={{ from: "md" }}
                            $left={0}
                            $position="fixed"
                            $px={8}
                            $py={8}
                            $right={0}
                            $top={0}
                            $zIndex={1}
                        >
                            <ButtonOpaque
                                $py={6}
                                $textTransform="uppercase"
                                $underline={false}
                                onClick={handleCloseModal("no-action")}
                                startIcon={<Icon name="chevronLeftCircleOutline" size={24} $mr={16} />}
                            >
                                Zavřít
                            </ButtonOpaque>
                        </Box>
                        <Box $position="absolute" $top={rem(24)} $right={rem(24)} $hidden={{ to: "md" }}>
                            <IconLink
                                $backgroundHoverColor={dark ? COLORS.yellow.default : undefined}
                                $color={dark ? COLORS.white : COLORS.green.default}
                                $focusColor={dark ? COLORS.white : undefined}
                                $hoverColor={dark ? COLORS.green.default : undefined}
                                iconSize={16}
                                name="cross"
                                onClick={handleCloseModal("no-action")}
                                size={24}
                                title="Zavřít"
                            />
                        </Box>
                    </>
                )}
                <Box
                    $color={dark ? COLORS.white : undefined}
                    $maxHeight={[size?.height ?? "100vh", null, size?.height ? size.height - 48 : "100vh"]}
                    $maxWidth={[null, null, `calc(100vw - ${rem(48)})`]}
                    $mt={!disableCloseButton ? [52, null, 0] : undefined}
                >
                    {children}
                </Box>
            </Box>
        </Root>
    );
});

Modal.displayName = "Modal";
