import { rem } from "@uxf/styles/utils/rem";
import { transparentize } from "color2k";

export const SHADOWS = {
    dropdown: `0 ${rem(4)} ${rem(20)} 0 ${transparentize("#000", 0.85)}`,
    navBar: `0px ${rem(4)} ${rem(15)} ${transparentize("#000", 0.95)}`,
    navBarUp: `0px ${rem(-4)} ${rem(8)} ${transparentize("#000", 0.95)}`,
    resultItem: `0px ${rem(8)} ${rem(32)} 0 ${transparentize("#000", 0.95)}`,
    shadow1: `0 ${rem(12)} ${rem(24)} 0 ${transparentize("#000", 0.95)}`,
    shadow2: `0 ${rem(12)} ${rem(36)} 0 ${transparentize("#000", 0.93)}`,
    shadow3: `0 0 ${rem(20)} ${rem(-3)} ${transparentize("#000", 0.9)}`,
    shadowUp: `0 ${rem(-4)} ${rem(4)} 0 ${transparentize("#000", 0.9)}`,
};

export type Shadows = typeof SHADOWS;
export type ShadowsSet = keyof typeof SHADOWS;
