import { Breakpoints, BREAKPOINTS } from "@ui/theme/breakpoints";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { responsiveHidden } from "@uxf/styles/mixins/responsiveHidden";
import { responsiveHideVisually } from "@uxf/styles/mixins/responsiveHideVisually";
import { Colors } from "@uxf/styles/properties/colors";
import { Display } from "@uxf/styles/properties/display";
import { FlexChild } from "@uxf/styles/properties/flexChild";
import { Margins } from "@uxf/styles/properties/margins";
import { Overflow } from "@uxf/styles/properties/overflow";
import { Sizing } from "@uxf/styles/properties/sizing";
import { Visibility } from "@uxf/styles/properties/visibility";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import { SVGAttributes } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export interface IconSvgProps
    extends SVGAttributes<SVGSVGElement>,
        Pick<Colors, "$color">,
        Display,
        Omit<FlexChild, "$flexShrink" | "$order">,
        Margins,
        Sizing,
        Pick<Overflow, "$overflow">,
        Pick<Visibility<Breakpoints>, "$hideVisually" | "$hidden"> {
    $css?: FlattenSimpleInterpolation;
}

export const IconSvg = styled("svg")<IconSvgProps>(
    ({
        $alignSelf,
        $color,
        $css,
        $display = "inline",
        $flexBasis,
        $flexGrow,
        $height,
        $hidden,
        $hideVisually,
        $maxHeight,
        $maxWidth,
        $mb,
        $minHeight,
        $minWidth,
        $ml,
        $mr,
        $mt,
        $mx,
        $my,
        $overflow,
        $width,
    }) => css`
        flex-shrink: 0;

        use {
            pointer-events: none;
        }

        ${resolveResponsiveProperties(BREAKPOINTS, {
            alignSelf: formatResponsiveValue($alignSelf),
            color: formatResponsiveValue($color),
            display: formatResponsiveValue($display),
            flexBasis: formatResponsiveValue($flexBasis),
            flexGrow: formatResponsiveValue($flexGrow, true),
            height: formatResponsiveValue($height),
            marginBottom: formatResponsiveValue($mb ?? $my),
            marginLeft: formatResponsiveValue($ml ?? $mx),
            marginRight: formatResponsiveValue($mr ?? $mx),
            marginTop: formatResponsiveValue($mt ?? $my),
            maxHeight: formatResponsiveValue($maxHeight),
            maxWidth: formatResponsiveValue($maxWidth),
            minHeight: formatResponsiveValue($minHeight),
            minWidth: formatResponsiveValue($minWidth),
            overflow: formatResponsiveValue($overflow),
            width: formatResponsiveValue($width),
        })};

        ${responsiveHideVisually(BREAKPOINTS, $hideVisually)};
        ${responsiveHidden(BREAKPOINTS, $hidden)};

        ${injectCss($css)};
    `,
);
