export const INSURANCE_PARTNERS_IDS = {
    ALLIANZ: 10,
    AXA: 11,
    CPP: 3,
    CSOB: 5,
    DIRECT: 4,
    GENERALI: 7,
    KOOPERATIVA: 9,
    PILLOW: 1,
    SLAVIA: 2,
    UNIQA: 8,
};

export const INSURANCE_PARTNERS_SEO_NAMES = {
    ALLIANZ: "allianz-pojistovna-a-s",
    AXA: "inter-partner-assistance-clen-skupiny-axa",
    CPP: "ceska-podnikatelska-pojistovna-a-s-vienna-insurance-group",
    CSOB: "csob-pojistovna-a-s-clen-holdingu-csob",
    DIRECT: "direct-pojistovna-a-s",
    GENERALI: "generali-ceska-pojistovna-a-s",
    KOOPERATIVA: "kooperativa-pojistovna-a-s-vienna-insurance-group",
    PILLOW: "pillow-pojistovna-a-s",
    SLAVIA: "slavia-pojistovna-a-s",
    UNIQA: "uniqa-pojistovna-a-s",
};
