import Head from "next/head";
import { FC, memo } from "react";

const FaviconComponent: FC = () => (
    <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png?v=wAMo9oK4rl" />
        <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png?v=wAMo9oK4rl" />
        <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png?v=wAMo9oK4rl" />
        <link rel="manifest" href="/static/favicon/site.webmanifest?v=wAMo9oK4rl" crossOrigin="use-credentials" />
        <link rel="mask-icon" href="/static/favicon/safari-pinned-tab.svg?v=wAMo9oK4rl" color="#04534c" />
        <link rel="shortcut icon" href="/static/favicon/favicon.ico?v=wAMo9oK4rl" />
        <meta name="apple-mobile-web-app-title" content="Kalkul&aacute;tor.cz" />
        <meta name="application-name" content="Kalkul&aacute;tor.cz" />
        <meta name="msapplication-TileColor" content="#04534c" />
        <meta name="msapplication-config" content="/static/favicon/browserconfig.xml?v=wAMo9oK4rl" />
        <meta name="theme-color" content="#ffffff" />
    </Head>
);

export const Favicon = memo(FaviconComponent);

Favicon.displayName = "Favicon";
