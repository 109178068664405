import { slugify } from "@uxf/core/utils/slugify";

function parseSeoNameAndId(value: string | string[] | undefined): [string, number] {
    if (!value || Array.isArray(value)) {
        throw new Error();
    }

    const array = value.split("--");

    if (array.length !== 2 || isNaN(parseInt(array[1], 10))) {
        throw new Error();
    }
    return [array[0], parseInt(array[1], 10)];
}

function stringifySeoNameAndId(seoName: string, id: number): string {
    return `${slugify(seoName)}--${id}`;
}

export const RouterHelper = {
    stringifySeoNameAndId,
    parseSeoNameAndId,
};
