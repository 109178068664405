import { memo } from "react";

const WOLVES = {
    career: {
        height: 554,
        name: "vlk-kariera",
        width: 545,
    },
    cookies: {
        height: 481,
        name: "vlk-cookies",
        width: 315,
    },
    energo: {
        height: 470,
        name: "vlk-energo",
        width: 303,
    },
    finance: {
        height: 703,
        name: "vlk-finance",
        width: 382,
    },
    financeWithPercent: {
        height: 587,
        name: "vlk-finance-procento",
        width: 338,
    },
    insurance: {
        height: 610,
        name: "vlk-pojisteni",
        width: 291,
    },
    insuranceMexico: {
        height: 493,
        name: "vlk-pojisteni-mexiko",
        width: 341,
    },
    insuranceProperty: {
        height: 511,
        name: "vlk-pojisteni-majetek",
        width: 186,
    },
    insurancePropertySitting: {
        height: 411,
        name: "vlk-pojisteni-majetek-sedici",
        width: 294,
    },
    insuranceTraveler: {
        height: 472,
        name: "vlk-pojisteni-cestovatel",
        width: 443,
    },
    insuranceWithUmbrellaPeeking: {
        height: 428,
        name: "vlk-pojisteni-s-destnikem-vykukujici",
        width: 465,
    },
    sitting: {
        height: 527,
        name: "vlk-sedici",
        width: 440,
    },
    tooltip: {
        height: 354,
        name: "vlk-tooltip",
        width: 250,
    },
    withCalculatorPeeking: {
        height: 462,
        name: "vlk-s-kalkulackou-vykukujici",
        width: 390,
    },
    withComputer: {
        height: 515,
        name: "vlk-s-pocitacem",
        width: 449,
    },
    withMagnifier: {
        height: 594,
        name: "vlk-s-lupou",
        width: 414,
    },
    withPhone: {
        height: 560,
        name: "vlk-s-telefonem",
        width: 266,
    },
};

export type WolfType = keyof typeof WOLVES;

interface Props {
    className?: string;
    name: WolfType;
}

export const Wolf = memo<Props>((props) => {
    const wolf = WOLVES[props.name];

    return (
        <img
            alt=""
            className={props.className}
            height={wolf.height}
            src={"/static/images/brand-2023/" + wolf.name + ".svg"}
            width={wolf.width}
        />
    );
});

Wolf.displayName = "Wolf";
