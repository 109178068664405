import { COOKIE_CONSENT_VERSION } from "@shared/utils/cookieConsent/cookie-consent.constants";
import { isConsentCookieSet } from "@uxf/analytics/consent";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { CookieConsentBar } from "./cookie-consent-bar";

const Component: FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        const isConsentSet = isConsentCookieSet(null, COOKIE_CONSENT_VERSION);
        if (!isConsentSet) {
            setIsOpen(true);
        }
    }, []);

    return <>{isOpen && <CookieConsentBar onClose={closeModal} />}</>;
};

export const CookieConsent = memo((props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component {...props} /> : null;
});

CookieConsent.displayName = "CookieConsent";
