import { Box } from "@ui/components/Box/Box";
import { Loader } from "@ui/components/Loader/Loader";
import { Paddings } from "@uxf/styles/properties/paddings";
import { ColorProperty } from "@uxf/styles/types";
import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react";
import { ContentContainer } from "./styles/ContentContainer";
import { OuterContainer } from "./styles/OuterContainer";
import { StyledClickable, StyledClickableProps } from "./styles/StyledClickable";
import { TextContainer, TextContainerProps } from "./styles/TextContainer";

export interface ButtonProps extends StyledClickableProps, Pick<Paddings, "$px" | "$py" | "$pl" | "$pr"> {
    $fontSize?: TextContainerProps["$fontSize"];
    $fontWeight?: TextContainerProps["$fontWeight"];
    $lineHeight?: TextContainerProps["$lineHeight"];
    $loaderColor?: ColorProperty;
    $textAlign?: TextContainerProps["$textAlign"];
    $textPl?: TextContainerProps["$pl"];
    $textPr?: TextContainerProps["$pr"];
    $textPx?: TextContainerProps["$px"];
    $textTransform?: TextContainerProps["$textTransform"];
    endIcon?: ReactNode;
    loading?: boolean;
    maxLines?: number;
    startIcon?: ReactNode;
}

const ButtonComponent: ForwardRefRenderFunction<HTMLAnchorElement, ButtonProps> = (props, ref) => {
    const {
        $fontSize,
        $fontWeight,
        $lineHeight,
        $loaderColor,
        $pl,
        $pr,
        $px = [24, null, 32],
        $py,
        $textAlign = "center",
        $textPl,
        $textPr,
        $textPx,
        $textTransform,
        children,
        endIcon,
        loading,
        maxLines,
        startIcon,
        ...restProps
    } = props;

    return (
        <StyledClickable loading={loading} ref={ref} {...restProps}>
            <OuterContainer $pl={$pl} $pr={$pr} $px={$px} $py={$py}>
                {!!loading && <Loader $color={$loaderColor} $absolute />}
                <ContentContainer>
                    {!!startIcon && <Box $flexShrink={0}>{startIcon}</Box>}
                    <TextContainer
                        $fontSize={$fontSize}
                        $fontWeight={$fontWeight}
                        $lineHeight={$lineHeight}
                        $maxLines={maxLines && maxLines > 1 ? maxLines : undefined}
                        $pl={$textPl}
                        $pr={$textPr}
                        $px={$textPx}
                        $textAlign={$textAlign}
                        $textTransform={$textTransform}
                        $truncate={maxLines === 1}
                    >
                        {children}
                    </TextContainer>
                    {!!endIcon && <Box $flexShrink={0}>{endIcon}</Box>}
                </ContentContainer>
            </OuterContainer>
        </StyledClickable>
    );
};

export const Button = forwardRef(ButtonComponent);

Button.displayName = "Button";
