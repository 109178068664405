import { INSURANCE_PARTNERS_IDS, INSURANCE_PARTNERS_SEO_NAMES } from "@config/insurance-partners";

export const ENERGO_PARTNERS = [
    {
        id: 479,
        title: "E.ON",
        seoName: "eon",
    },
    {
        id: 482,
        title: "ČEZ",
        seoName: "cez",
    },
    {
        id: 494,
        title: "MND",
        seoName: "mnd",
    },
    {
        id: 472,
        title: "innogy",
        seoName: "rwe",
    },
    {
        id: 484,
        title: "Centropol",
        seoName: "centropol-energy",
    },
    {
        id: 532,
        title: "Nano Energies",
        seoName: "nano-energies-trade",
    },
];

export const VEHICLE_INSURANCE_PARTNERS = [
    {
        id: INSURANCE_PARTNERS_IDS.ALLIANZ,
        title: "Allianz",
        seoName: INSURANCE_PARTNERS_SEO_NAMES.ALLIANZ,
    },
    {
        id: INSURANCE_PARTNERS_IDS.CSOB,
        title: "ČSOB Pojišťovna",
        seoName: INSURANCE_PARTNERS_SEO_NAMES.CSOB,
    },
    {
        id: INSURANCE_PARTNERS_IDS.DIRECT,
        title: "Direct Pojišťovna",
        seoName: INSURANCE_PARTNERS_SEO_NAMES.DIRECT,
    },
    {
        id: INSURANCE_PARTNERS_IDS.GENERALI,
        title: "Generali Česká pojišťovna",
        seoName: INSURANCE_PARTNERS_SEO_NAMES.GENERALI,
    },
    {
        id: INSURANCE_PARTNERS_IDS.KOOPERATIVA,
        title: "Kooperativa",
        seoName: INSURANCE_PARTNERS_SEO_NAMES.KOOPERATIVA,
    },
    {
        id: INSURANCE_PARTNERS_IDS.CPP,
        title: "ČPP",
        seoName: INSURANCE_PARTNERS_SEO_NAMES.CPP,
    },
];

export const FINANCE_PARTNERS = [
    {
        id: 1,
        title: "Česká spořitelna",
        seoName: "ceska-sporitelna",
    },
    {
        id: 2,
        title: "Home Credit",
        seoName: "home-credit",
    },
];
