import { CookieConsentFormProps } from "@shared/forms/cookie-consent-form/cookie-consent-form";
import type { KalkulatorCookieConsent } from "@shared/utils/cookieConsent/cookie-consent.types";
import { setCookieConsent } from "@shared/utils/cookieConsent/set-cookie-consent";
import { readConsentFromCookie } from "@uxf/analytics/consent";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import dynamic from "next/dynamic";
import { FC, memo, useCallback, useMemo } from "react";

export interface CookieConsentFormContainerProps {
    closeModal: () => void;
}

const CookieConsentForm = dynamic<CookieConsentFormProps>(
    () =>
        import("@shared/forms/cookie-consent-form/cookie-consent-form").then(
            (component) => component.CookieConsentForm,
        ),
    {
        ssr: false,
    },
);

const Component: FC<CookieConsentFormContainerProps> = ({ closeModal }) => {
    const initialValues: CookieConsentFormProps["initialValues"] = useMemo(() => {
        const consentData = readConsentFromCookie();

        return {
            analytics: typeof consentData.analytics_storage === "boolean" ? consentData.analytics_storage : true,
            functional:
                "functional" in consentData && typeof consentData.functional === "boolean"
                    ? consentData.functional
                    : true,
            marketing: typeof consentData.ad_storage === "boolean" ? consentData.ad_storage : true,
        };
    }, []);

    const onSubmit: CookieConsentFormProps["onSubmit"] = useCallback(
        (values) => {
            const all = values.type === "all";
            const consentData: KalkulatorCookieConsent = {
                analytics_storage: !all ? values.analytics : true,
                ad_storage: !all ? values.marketing : true,
                ad_user_data: !all ? values.marketing : true,
                ad_personalization: !all ? values.marketing : true,
                functional: !all ? values.functional : true,
            };

            setCookieConsent(consentData);
            closeModal();
        },
        [closeModal],
    );

    return <CookieConsentForm onSubmit={onSubmit} initialValues={initialValues} />;
};

const Wrapper: FC<CookieConsentFormContainerProps> = (props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component {...props} /> : null;
};

export const CookieConsentFormContainer = memo(Wrapper);

CookieConsentFormContainer.displayName = "CookieConsentFormContainer";
