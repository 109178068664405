import { ICONS, IconsSet } from "@config/icons";
import { ICONS_VERSION, ICON_SPRITE } from "@config/icons-config";
import { ForwardRefRenderFunction, forwardRef, memo } from "react";
import { IconSvg, IconSvgProps } from "./styles/IconSvg";

type OmittedProps = "height" | "name" | "preserveAspectRatio" | "viewBox" | "width";
type NameOrComponentType =
    | {
          name: IconsSet;
          component?: never;
      }
    | {
          name?: never;
          component: any;
      };

export type IconProps = Omit<IconSvgProps, OmittedProps> & {
    mode?: "meet" | "slice";
    size?: IconSvgProps["$width"];
} & NameOrComponentType;

const IconComponent: ForwardRefRenderFunction<SVGSVGElement, IconProps> = (props, ref) => {
    const { $height, $width, mode = "meet", name, component, size, ...restProps } = props;

    const sizes = name ? ICONS[name] : null;

    return sizes ? (
        <IconSvg
            $height={$height ?? size ?? sizes.h}
            $width={$width ?? size ?? sizes.w}
            preserveAspectRatio={`xMidYMid ${mode}`}
            ref={ref}
            role="img"
            viewBox={`0 0 ${sizes.w} ${sizes.h}`}
            {...restProps}
        >
            <use xlinkHref={`${ICON_SPRITE}?v=${ICONS_VERSION}#icon-sprite--${name}`} />
        </IconSvg>
    ) : (
        <IconSvg
            as={component}
            $height={$height ?? size}
            $width={$width ?? size}
            preserveAspectRatio={`xMidYMid ${mode}`}
            ref={ref}
            role="img"
            {...restProps}
        />
    );
};

export const Icon = memo(forwardRef(IconComponent));

Icon.displayName = "Icon";
