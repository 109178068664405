import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { paddings, Paddings } from "@uxf/styles/properties/paddings";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface OuterContainerProps extends HTMLAttributes<HTMLSpanElement>, Paddings {}

export const OuterContainer = styled("span")<OuterContainerProps>(
    ({ $pb, $pl, $pr, $pt, $py, $px }) => css`
        align-items: center;
        border-radius: inherit;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        min-width: 0;
        position: relative;

        ${paddings({ breakpoints: BREAKPOINTS, $pb, $pl, $pr, $pt, $py, $px })};
    `,
);
