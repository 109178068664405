import { config } from "@config/config";
import { useGtmScript } from "@uxf/analytics/gtm";
import { isServer } from "@uxf/core/utils/isServer";
import Head from "next/head";
import { FC, memo } from "react";

const AnalyticsComponent: FC = () => {
    const gtmScript = useGtmScript(config.GTM_ID as string);

    return (
        <Head>
            {config.GTM_ID && !isServer && <script dangerouslySetInnerHTML={{ __html: gtmScript }} />}
            <meta name="seznam-wmt" content="dsdL74Vs1hbeWTZgGO3oS5ZGMvaF0RrG" />
        </Head>
    );
};

export const Analytics = memo(AnalyticsComponent);

Analytics.displayName = "Analytics";
