// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    "americanMortgage": { w: 80, h: 50 },
    "arrowDown": { w: 37.14, h: 40 },
    "arrowDownCircleFilled": { w: 40, h: 40 },
    "arrowDownCircleFilledYellow": { w: 40, h: 40 },
    "arrowDownCircleOutline": { w: 40, h: 40 },
    "arrowDownFilled": { w: 40, h: 30.03 },
    "arrowLeftCircleFilled": { w: 40, h: 40 },
    "arrowLeftCircleFilledYellow": { w: 40, h: 40 },
    "arrowLeftCircleOutline": { w: 40, h: 40 },
    "arrowRightCircleFilled": { w: 40, h: 40 },
    "arrowRightCircleFilledYellow": { w: 40, h: 40 },
    "arrowRightCircleOutline": { w: 40, h: 40 },
    "arrowRightSimpleThin": { w: 21.54, h: 40 },
    "arrowUp": { w: 37.14, h: 40 },
    "arrowUpCircleFilled": { w: 40, h: 40 },
    "arrowUpCircleFilledYellow": { w: 40, h: 40 },
    "arrowUpCircleOutline": { w: 40, h: 40 },
    "badge": { w: 26.96, h: 40 },
    "badgeCircleFilledDark": { w: 40, h: 40 },
    "bankNotes": { w: 40, h: 26.77 },
    "building": { w: 37.03, h: 40 },
    "bullets": { w: 40, h: 32.85 },
    "cabinPlusLivingRoom": { w: 40, h: 12.53 },
    "calculationSheet": { w: 37.12, h: 40 },
    "calendarChecked": { w: 40, h: 39.23 },
    "calendarSelectedDate": { w: 40, h: 39.23 },
    "car": { w: 40, h: 28.44 },
    "checkmark": { w: 40, h: 31.19 },
    "checkmarkCircleFilledDark": { w: 40, h: 40 },
    "checkmarkCircleOutline": { w: 40, h: 40 },
    "checkmarkCircleOutlineRounded": { w: 40, h: 40 },
    "checkCircleOutlineAlt": { w: 40, h: 40 },
    "checkmarkRounded": { w: 40, h: 28.24 },
    "chevronDown": { w: 40, h: 26.01 },
    "chevronDownCircleFilled": { w: 40, h: 40 },
    "chevronDownCircleFilledYellow": { w: 40, h: 40 },
    "chevronDownCircleOutline": { w: 40, h: 40 },
    "chevronLeft": { w: 26.01, h: 40 },
    "chevronLeftCircleFilled": { w: 40, h: 40 },
    "chevronLeftCircleFilledYellow": { w: 40, h: 40 },
    "chevronLeftCircleOutline": { w: 40, h: 40 },
    "chevronRight": { w: 26.01, h: 40 },
    "chevronRightCircleFilled": { w: 40, h: 40 },
    "chevronRightCircleFilledYellow": { w: 40, h: 40 },
    "chevronRightCircleOutline": { w: 40, h: 40 },
    "chevronSkew": { w: 33.97, h: 40 },
    "chevronUp": { w: 40, h: 26.01 },
    "chevronUpCircleFilled": { w: 40, h: 40 },
    "chevronUpCircleFilledYellow": { w: 40, h: 40 },
    "chevronUpCircleOutline": { w: 40, h: 40 },
    "cross": { w: 40, h: 40 },
    "crossCircleFilledYellow": { w: 40, h: 40 },
    "document": { w: 32.73, h: 40 },
    "documentAlt": { w: 33.67, h: 40 },
    "documentFolder": { w: 39.9, h: 40 },
    "documentPdf": { w: 33.9, h: 40 },
    "documentPlus": { w: 32.73, h: 40 },
    "documentTorn": { w: 33.9, h: 40 },
    "documentWriting": { w: 40, h: 39.17 },
    "drawnArrowDown": { w: 40, h: 15.89 },
    "drawnArrowLeft": { w: 13.29, h: 40 },
    "drawnArrowRight": { w: 13.29, h: 40 },
    "drawnArrowUp": { w: 40, h: 15.89 },
    "editPencil": { w: 40, h: 40 },
    "energy": { w: 40, h: 37.15 },
    "envelope": { w: 40, h: 38.69 },
    "envelopeAlt": { w: 40, h: 30.83 },
    "exit": { w: 26.68, h: 40 },
    "fa-light-phone-volume": { w: 512, h: 512 },
    "fa-light-tv-retro": { w: 512, h: 512 },
    "fa-light-wifi": { w: 640, h: 512 },
    "fa-regular-tv-retro": { w: 512, h: 512 },
    "fa-solid-wifi": { w: 640, h: 512 },
    "facebookFilled": { w: 40, h: 40 },
    "facebookOutline": { w: 40, h: 40 },
    "filter": { w: 36.63, h: 40 },
    "filterCircleFilledYellow": { w: 40, h: 40 },
    "gear": { w: 39.18, h: 40 },
    "gift": { w: 40, h: 40 },
    "giftAlt": { w: 40, h: 37.9 },
    "graphEye": { w: 40, h: 23.53 },
    "highway": { w: 40, h: 34.5 },
    "house": { w: 40, h: 37.87 },
    "houseApartment": { w: 40, h: 32.46 },
    "houseCabin": { w: 40, h: 30.86 },
    "houseFamily": { w: 40, h: 30.86 },
    "infoBubble": { w: 40, h: 40 },
    "infoCircleFilled": { w: 40, h: 40 },
    "infoCircleFilledNegative": { w: 40, h: 40 },
    "instagramOutline": { w: 40, h: 40 },
    "invoice": { w: 34.13, h: 40 },
    "kalkulatorLogo": { w: 40, h: 28.41 },
    "lightning": { w: 26.47, h: 40 },
    "linkedin": { w: 448, h: 512 },
    "livingRoom": { w: 40, h: 29.48 },
    "magnifyingGlass": { w: 40, h: 40 },
    "mapMarker": { w: 29.65, h: 40 },
    "mapMarkerRecurring": { w: 40, h: 39.23 },
    "mapPointToPoint": { w: 40, h: 39.23 },
    "megaphone": { w: 40, h: 40 },
    "menu": { w: 40, h: 37.78 },
    "minus": { w: 14, h: 2 },
    "monitor": { w: 40, h: 32.04 },
    "padlock": { w: 33.66, h: 40 },
    "pause": { w: 34.28, h: 40 },
    "personalData": { w: 29.6, h: 40 },
    "personalDataAlt": { w: 30.37, h: 40 },
    "phone": { w: 40, h: 40 },
    "phoneCircleFilledYellow": { w: 40, h: 40 },
    "phoneDial": { w: 34, h: 34 },
    "phoneMessage": { w: 40, h: 37.96 },
    "piggyBank": { w: 40, h: 36.77 },
    "plus": { w: 14, h: 14 },
    "plusCircle": { w: 40, h: 40 },
    "plusCircleFilled": { w: 40, h: 40 },
    "plusCircleOutlineDouble": { w: 40, h: 32.31 },
    "pointToPoint": { w: 40, h: 32.31 },
    "realEstateConstruction": { w: 80, h: 50 },
    "realEstatePurchase": { w: 80, h: 50 },
    "realEstateReconstruction": { w: 80, h: 50 },
    "refinancing": { w: 80, h: 50 },
    "scales": { w: 40, h: 38.03 },
    "secured": { w: 40, h: 29.53 },
    "setSaving": { w: 40, h: 17.87 },
    "star": { w: 40, h: 36.53 },
    "tariffBook": { w: 28.84, h: 40 },
    "tickCircleFilled": { w: 40, h: 40 },
    "tickCircleOutline": { w: 40, h: 40 },
    "trash": { w: 38.28, h: 40 },
    "twitterFilled": { w: 40, h: 32.34 },
    "twitterOutline": { w: 40, h: 37.01 },
    "upload": { w: 32.73, h: 40 },
    "uploadAlt": { w: 37.12, h: 40 },
    "uploadCloud": { w: 40, h: 26.21 },
    "user": { w: 40, h: 40 },
    "virus": { w: 40, h: 39.91 },
    "voucher": { w: 40, h: 30.18 },
    "warning": { w: 40, h: 35.47 },
    "website": { w: 40, h: 35.11 },
    "wolf": { w: 40, h: 33.32 },
    "wolfMessage": { w: 40, h: 28.17 },
    "wolfPhone": { w: 40, h: 35.23 },
    "wolfSmall": { w: 40, h: 32.91 },
    "wolfWatchSaving": { w: 40, h: 33.98 },
    "wolfSet": { w: 40, h: 28 },
} as const;

export type IconsSet = keyof typeof ICONS;
